import $ from 'jquery';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'slick-carousel';

export default class Carousel {

  constructor() {
    this.init();
  }

  /**
   * Init sliders
   *
   * (jQuery dependent)
   */
  init() {
    $( () => {
      $('[data-slick]').each( (i, elm) => {
        $(elm).slick();
      });
    });
  }

}
