import React from 'react';

export default function TeaserMarqueeNews ({post, theme})
{

  let post_link;
  let post_target;

  if (post.external_url) {
    post_link   = post.external_url;
    post_target = '_blank';
  } else {
    post_link = post.link;
    post_target = '_self';
  }

  return (
    <article class="TeaserMarqueeNews">
      <a href={post_link} target={post_target} className="group space-y-4">
        <div className="image-box image-box-4/3 rounded-md">
          <div
            className="image"
            style={{backgroundImage: 'url(' + post.featured_image.url + ')'}}
          ></div>
        </div>
        <div className="px-4">
          <span className={`${ theme == 'dark' ? 'text-blue-300' : 'text-blue-400' } type-preset-8 font-bold`}>{post.category}</span>
          <h3 className={`type-preset-6 font-bold ${ theme == 'dark' ? 'text-white' : 'text-slate-300' } group-hover:underline`} dangerouslySetInnerHTML={{__html: post.title.rendered}}></h3>
        </div>
      </a>
    </article>
  )
}
