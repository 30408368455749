import React from 'react'

export default function InsightTeaserAuthor({name, image, category})
{
  return (
    <div className="flex items-center space-x-2">
      {image &&
      <div>
        <div className="image-box image-box-1/1 image-box-circle w-10">
          <div className="image" style={{backgroundImage: `url(${image})`}} />
        </div>
      </div>
      }
      <div className="type-preset-8">
        <div className="text-orange-200 font-bold">{category}</div>
        {name &&
          <div>by {name}</div>
        }
      </div>
    </div>
  )
}
