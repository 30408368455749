import React from 'react'
import moment from 'moment'

export default function EventTeaser({model})
{
  return (
    <a href={model.url} className="space-y-4 group">
      {model.image &&
        <div
          className={`image-box image-box-1/2 rounded-md`}>
          <div className="image" style={{backgroundImage: 'url(' + model.image.url + ')'}}></div>
        </div>
      }
      <div>
        <time className="type-preset-7 font-bold text-blue-400">{formatMoment(model.start_date, model.end_date)}</time>
        <h3 className="type-preset-5 font-bold text-slate-300 group-hover:underline" dangerouslySetInnerHTML={{__html: model.title}}></h3>
      </div>
    </a>
  )
}

function formatMoment(startDatetime, endDatetime)
{
  let result = moment(startDatetime, 'YYYY-MM-DD hh:mm:ss').format(endDatetime ? 'MMM D h:mma' : 'MMM D, YYYY h:mma')
  if (endDatetime)
    result += ' - ' + moment(endDatetime, 'YYYY-MM-DD hh:mm:ss').format('MMM D, YYYY h:mma')
  result += ' EST'

  return result
}
