// dependencies
require('jquery')
require('alpinejs')

// classes
require('./classes/tippy')
require('./classes/particles')
import NavigationMenus from './classes/NavigationMenus'

NavigationMenus.init()

// components
new (require('./components/Carousel').default);
new (require('./components/QuoteCarousel').default);
new (require('./components/ImageBlock').default);

// react
import ReactComponentLoader from './classes/ReactComponentLoader'

ReactComponentLoader({
  'newsArchive': require('./components/NewsArchive').default,
  'teaserMarquee': require('./components/TeaserMarquee').default,
  'rowNewsInsights': require('./components/row/NewsCarousel').default,
  'eventsArchive': require('./components/eventsArchive').default
})
