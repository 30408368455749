import $ from 'jquery';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'slick-carousel';

export default class QuoteCarousel {

  constructor() {
    // slider settings
    this.settings = {
      adaptiveHeight: true,
      arrows: true,
      appendArrows: '.quote-carousel__arrows',
      prevArrow: '.quote-carousel__prev',
      nextArrow: '.quote-carousel__next',
      autoplay: false,
      dots: true,
      appendDots: '.quote-carousel__dots',
      fade: true,
      mobileFirst: true,
      pauseOnFocus: false,
      pauseOnHover: false,
      // responsive: {},
    };

    this.init();
  }

  /**
   * Init sliders
   *
   * (jQuery dependent)
   */
  init() {
    $( () => {
      $('.quote-carousel__wrap').each( (i, elm) => {
        $(elm).slick(this.settings);
      });
    });
  }

}
