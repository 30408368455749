require('particles.js')

document.addEventListener("DOMContentLoaded", init);
function init () {
  // find all elements we need particles applied to
  // and loop through them to init particles.js
  const particleElems = document.querySelectorAll('.js-particles');

  if ( !particleElems )
    return;

  for ( let i = 0; i < particleElems.length; i++ ) {

    let particleElem = particleElems[i];

    // set unique ID if it doesn't exist
    if ( !particleElem.id )
      particleElem.id = `particles-js-${i + 1}`;

    // load particles.js by unique ID
    particlesJS.load(particleElem.id, '/wp-content/themes/columbia-dsi/particles.json');

  }
}
