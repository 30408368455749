import React, {useEffect, useState} from 'react'
import superagent from 'superagent'
import EventTeaser from './EventTeaser'
import LoadingIndicator from './LoadingIndicator'

export default function EventsArchive({past})
{
  const [events, setEvents] = useState([])
  const [isLoading, setIsLoading] = useState()
  const [page, setPage] = useState(1)
  const [canShowMore, setCanShowMore] = useState()

  useEffect(() => {
    let url = past ?
      // custom endpoint for controlling order by event date
      `/wp-json/dsi/v1/events/past?page=${page}&per_page=9` :
      // future events
      `/wp-json/tribe/events/v1/events?page=${page}&per_page=9`;

    // get posts via REST API
    setIsLoading(true)
    superagent.get(url)
    .then(res => {
      setEvents(_.concat(events, res.body.events))
      setCanShowMore(page < res.body.total_pages)
      setIsLoading(false)
    })
  }, [page])

  return (
    <div className="space-y-8">
      <div className="grid md:grid-cols-3 gap-8">
        {events && events.map(event => (
          <EventTeaser key={event.id} model={event} />
        ))}
      </div>
      {isLoading && <div className="flex justify-center"><LoadingIndicator /></div>}
      {canShowMore && <button className="btn--large" onClick={() => setPage(page+1) }>Load More Events</button>}
    </div>
  )
}
