/** @jsx jsx */
import React, {useEffect, useState} from 'react'
import superagent from 'superagent'
import TeaserMarqueeNews from './TeaserMarqueeNews'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { css, jsx } from '@emotion/core'

export default function TeaserMarquee ({title, postIds, theme})
{
  const [query, setQuery] = useState({
    postids: postIds ? postIds.join(',') : []
  })
  const [posts, setPosts] = useState([])
  const [isLoading, setLoading] = useState(false)

  var settings = {
    dots: false,
    arrows: false,
    infinite: true,
    // linear speed equation based on number of posts
    speed: ((postIds.length * 500) + 500),
    centerMode: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 1,
    autoplay: true,
    autoplaySpeed: 0,
    cssEase: 'linear',
    zIndex: 10,
    responsive: [
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 640,
        settings: {
          autoplay: false,
          dots: true,
          slidesToShow: 1,
          speed: 300,
          centerPadding: '65px',
        }
      },
    ],
    css: css`
      padding-top: 1rem;
      padding-bottom: 1.5rem;
    `,
    appendDots: dots => (
      <div
        css={css`
          bottom: -2rem;

          li {
            width: 14px;
            margin: 0 2px;
          }

          li.slick-active > div {
            background-color: #ff6a17 !important;
          }
        `}
      >
        <ul style={{ margin: "0px" }}> {dots} </ul>
      </div>
    ),
    customPaging: i => (
      <div css={css`
        height: 9px;
        width: 9px;
        border-radius: 9px;
        background-color: #c4d8e2;
      `}></div>
    )
  };
  const defaultArrowStyle = `
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 50%;
    display: block;
    -webkit-transform: translate(0, -50%);
    -o-transform: translate(0, -50%);
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
    background: transparent;
    border-radius: 100px;
    padding: 1rem;
    border: 1px solid #dadce0;
    opacity: .5;
    transition: opacity .125s ease-out;
    &:hover {
      opacity: 1;
    }
    &:active {
      background-color: #dadce0;
    }
  `
  useEffect(() => {
    fetchPosts()
  }, [query])

  function fetchPosts ()
  {
    setLoading(true)
    superagent.get(getUrl())
    .then(res => {
      setPosts(query.page > 1 ? _.concat(posts, res.body) : res.body)
      setLoading(false)
    })
  }

  function getUrl ()
  {
    let url = `/wp-json/dsi/v1/allposts?` +
      _.map(query, (value, key) => value ? `${key}=${value}` : null)
        .filter((v) => v)
        .join('&')
    return url
  }

  return (
    <section className="py-8 space-y-6 TeaserMarquee">
      <header
        className="container mx-auto border-b border-solid border-gray-200 font-medium">
          <h2 className={`type-preset-5 py-2 ${theme == 'dark' ? 'text-white' : 'text-slate-200'}`}>{title}</h2>
      </header>
      <Slider {...settings}>
        {posts.map(post => <div key={post.id} className="px-4"><TeaserMarqueeNews post={post} theme={theme} /></div>)}
        {/* {posts.map(post => <div key={post.id} className="px-4"><TeaserMarqueeNews post={post} theme={theme} /></div>)} */}
      </Slider>
    </section>
  )
}
