import $ from 'jquery';

export default {
  /**
   * Loads all menu functionality
   */
  init() {
    this.primaryMenuInit();
  },
  /**
   * Loads primary menu functionality
   *
   * 1. Shows submenus via class toggles
   * 2. Hides desktop mega menu when clicking outside of it
   */
  primaryMenuInit() {
    // find top level links with sub-menus
    this.topLevelLinks = document.querySelectorAll('.menu-mobile > li.menu-item-has-children > a, .menu-desktop > li.menu-item-has-children > a');
    if ( !this.topLevelLinks.length )
      return;

    // class toggles for these top level items
    // (to activate/deactivate sub-menus)
    for ( let i = 0; i < this.topLevelLinks.length; i++ ) {
      let link = this.topLevelLinks[i];

      link.addEventListener( 'click', function(event) {
        event.preventDefault();
        $(event.target.parentElement)
          .toggleClass('is-active')
          .siblings()
          .removeClass('is-active');
      });
    }

    // close desktop mega menu when clicking outside of it
    document.body.addEventListener('click', function(event) {

      // get active mega menu toggle item
      let activeNavItem = document.querySelector('.menu-desktop > li.is-active');

      if (
        // make sure mega menu is active
        activeNavItem !== null &&
        // make sure we're not clicking on the actual menu item toggle
        event.target.closest('.menu-desktop') === null &&
        // make sure we're clicking outside of the mega menu
        event.target.closest('.mega-menu') === null
      ) {
        // close mega menu
        activeNavItem.classList.remove('is-active');
      }

    });
  },
}
