import React from 'react'

export default function NewsSingleItem ({post})
{

  console.log(post);

  let post_link;
  let post_target;

  if (post.acf.external_url) {
    post_link   = post.acf.external_url;
    post_target = '_blank';
  } else {
    post_link   = post.link;
    post_target = '_self';
  }

  return (
    <a href={post_link} target={post_target} className="group flex flex-col md:flex-row-reverse fluid:space-y-4 NewsSingleItem">
      <div className="md:w-64 relative md:ml-24">
        <div className="image-box image-box-4/3 rounded-md">
          <div
            className="image"
            style={{backgroundImage: 'url(' + post.featured_image.url + ')'}}
          ></div>
        </div>
      </div>
      <div className="md:flex-1 space-y-4">
        <h2 className="font-bold type-preset-6 group-hover:underline text-slate-300" dangerouslySetInnerHTML={{__html: post.title.rendered}}></h2>
        <p className="type-preset-8 text-slate-200" dangerouslySetInnerHTML={{__html: post.excerpt.rendered}}></p>
        <div className="flex items-center type-preset-8 text-slate-200 space-x-4">
          <span className="text-blue-400 font-bold">{post.category}</span>
          <time>{post.date_formatted}</time>
        </div>
      </div>
    </a>
  )
}
