/**
 * Functionality for WP's Image Block (Gutenberg)
 */
export default class ImageBlock {

  constructor() {
    this.imageBlocks = document.querySelectorAll('.gutenberg-editor .wp-block-image');

    [...this.imageBlocks].forEach( (imageBlock) => {
      this.setCaptionMaxWidth(imageBlock);
    });
  }

  /**
   * Set max width for captions
   *
   * This is based on the width attribute of the
   * image, if it exists.
   *
   * @param {Element} imageBlock
   */
  setCaptionMaxWidth(imageBlock) {
    let width = imageBlock.querySelector('img').getAttribute('width') || null;
    let caption = imageBlock.querySelector('figcaption') || null;

    if ( width && caption ) {
      caption.style.maxWidth = `${width}px`;
    }
  }

}
