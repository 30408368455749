import React from 'react'
import InsightTeaserAuthor from './InsightTeaserAuthor'

export default function NewsItemInsight ({post, theme})
{
  return (
    <article className="px-2">
      <a href={post.link} className="group space-y-4">
        <div className="image-box image-box-4/3 rounded-md">
          <div
            className="image"
            style={{backgroundImage: 'url(' + post.featured_image.url + ')'}}
          ></div>
        </div>
        <div className="px-4">
          <h3 className={`type-preset-7 font-bold ${theme === 'dark' ? 'text-white' : 'text-slate-300'} group-hover:underline`} dangerouslySetInnerHTML={{__html: post.title.rendered}}></h3>
          <InsightTeaserAuthor name={post.author.name} image={post.author.image} category={post.category} />
        </div>
      </a>
    </article>
  )
}
