import React, {useEffect, useRef, useState} from 'react'
import superagent from 'superagent'
import _, { set } from 'lodash'
import NewsSingleItem from './NewsSingleItem'
import LoadingIndicator from './LoadingIndicator'

export default function NewsArchive ({category = null})
{
  const [posts, setPosts] = useState([])
  const [query, setQuery] = useState({
    page: 1,
    search: null,
    per_page: 10,
    'categories[]': category
  })
  const [isLoading, setLoading] = useState(false)
  const [isLoadMoreVisible, setLoadMoreVisibility] = useState(true)
  const searchInput = useRef()

  useEffect(() => {
    fetchPosts()
  }, [query])

  function fetchPosts ()
  {
    setLoading(true)
    superagent.get(getUrl())
    .then(res => {
      setPosts(query.page > 1 ? _.concat(posts, res.body) : res.body)
      setLoadMoreVisibility(query.page < res.headers['x-wp-totalpages'])
      setLoading(false)
    })
  }

  function getUrl ()
  {
    let url = `/wp-json/wp/v2/posts?` +
      _.map(query, (value, key) => value ? `${key}=${value}` : null)
        .filter((v) => v)
        .join('&')
    return url
  }

  return (
    <div className="space-y-8 NewsArchive">
      <div className="container mx-auto">
        <SearchBar />
      </div>
      <div className="container mx-auto space-y-16 pb-20 lg:pb-32">
        <section className="space-y-16 xl:px-64">
          {posts.map(post => <NewsSingleItem key={post.id} post={post} /> )}
        </section>
        {isLoading && <div className="flex justify-center"><LoadingIndicator /></div>}
        {isLoadMoreVisible && <button className="btn--large" onClick={() => setQuery({...query, page: query.page+1}) }>Load More News</button>}
      </div>
    </div>
  )

  function SearchBar ()
  {
    return (
      <div class="max-w-3xl mx-auto">
        <div className="relative type-preset-4 text-slate-300">
          <input
            ref={searchInput}
            className="w-full bg-blue-200 rounded-md w-full bg-blue-200 py-5 px-6 pr-24 font-bold placeholder-slate-300"
            type="text"
            placeholder="Enter a search term" />
          <button
            className="absolute inset-y-0 right-0 mt-2 w-12 h-12 bg-blue-400 text-white rounded-full mt-4 mr-5"
            onClick={() => setQuery({...query, page: 1, search: searchInput.current.value})}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" class="ml-4">
              <path
                fill="#FFF"
                d="M3.059 9.94a4.83 4.83 0 01-1.431-3.445 4.83 4.83 0 011.431-3.444 4.858 4.858 0 013.453-1.427 4.85 4.85 0 013.453 1.428 4.826 4.826 0 011.431 3.443A4.83 4.83 0 019.965 9.94a4.856 4.856 0 01-3.453 1.427 4.856 4.856 0 01-3.453-1.428zM16 14.851l-4.365-4.354a6.435 6.435 0 001.389-4.003 6.437 6.437 0 00-1.908-4.592A6.478 6.478 0 006.512 0c-1.74 0-3.374.676-4.604 1.903A6.443 6.443 0 000 6.495c0 1.736.678 3.366 1.908 4.593a6.474 6.474 0 004.604 1.902 6.47 6.47 0 003.966-1.35L14.849 16 16 14.852z"/>
            </svg>
          </button>
        </div>
      </div>
    )
  }
}
