import React from 'react'
import ReactDOM from 'react-dom'

export default function ReactComponentLoader (components)
{
  document
    .querySelectorAll('[data-react-component]')
    .forEach((elm) => {
      if (!components[elm.dataset.reactComponent]) return
      let ReactComponent = components[elm.dataset.reactComponent]
      let props = elm.dataset.reactProps ? JSON.parse(elm.dataset.reactProps) : {}
      ReactDOM.render(<ReactComponent {...props} />, elm);
  });
}
