import React from 'react'

export default function LoadingIndicator ()
{
  return (
    <div className='loading-indicator'>
      <div className='loading__square'></div>
      <div className='loading__square'></div>
      <div className='loading__square'></div>
      <div className='loading__square'></div>
      <div className='loading__square'></div>
      <div className='loading__square'></div>
      <div className='loading__square'></div>
    </div>
  )
}
