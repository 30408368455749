/** @jsx jsx */
import React, {useEffect, useState} from 'react'
import superagent from 'superagent'
import Slider from "react-slick";
import NewsItemInsight from './NewsItemInsight';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { css, jsx } from '@emotion/core'

export default function NewsCarousel ({title, subtitle, postIds, theme})
{
  const [posts, setPosts] = useState()
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    initialSlide: 1,
    zIndex: 10,
    responsive: [
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 4,
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 2,
        }
      },
    ],
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    css: css`
      padding-top: 1rem;
    `,
    appendDots: dots => (
      <div
        css={css`
          bottom: -2rem;
          @media(min-width: 1024px) {
            width: 7rem;
            top: -2rem;
            bottom: auto;
            right: 0;
          }

          li {
            width: 14px;
            margin: 0 2px;
          }

          li.slick-active > div {
            background-color: #ff6a17 !important;
          }
        `}
      >
        <ul style={{ margin: "0px" }}> {dots} </ul>
      </div>
    ),
    customPaging: i => (
      <div css={css`
        height: 9px;
        width: 9px;
        border-radius: 9px;
        background-color: #c4d8e2;
      `}></div>
    )
  };
  const defaultArrowStyle = `
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 50%;
    z-index: 40;
    display: block;
    -webkit-transform: translate(0, -50%);
    -o-transform: translate(0, -50%);
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
    background: transparent;
    border-radius: 100px;
    padding: 1rem;
    border: 1px solid #dadce0;
    opacity: .5;
    transition: opacity .125s ease-out;
    &:hover {
      opacity: 1;
    }
    &:active {
      background-color: #dadce0;
    }
  `
  useEffect(() => {
    superagent.get('/wp-json/wp/v2/posts?include=' + postIds.join(','))
    .then(res => setPosts(res.body))
  }, [])

  function PrevArrow(props) {
    const { onClick } = props;
    return (
      <div
        css={css(defaultArrowStyle + `
          left: -1rem
        `)}
        onClick={onClick}
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"><path fill="none" stroke="#41516C" strokeWidth="2" d="M12.25 15l-7.5-7.501L12.25 0"/></svg>
      </div>
    );
  }

  function NextArrow(props) {
    const { onClick } = props;
    return (
      <div
        css={css(defaultArrowStyle + `
          right: -1rem
        `)}
        onClick={onClick}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          css={css`transform: rotateY(-180deg)`}
        >
          <path fill="none" stroke="#41516C" strokeWidth="2" d="M12.25 15l-7.5-7.501L12.25 0"/>
        </svg>
      </div>
    );
  }

  return (
    <div className="py-8 md:py-16">
      <header
        className={`border-b border-solid border-slate-100 font-medium md:flex ${theme === 'dark' ? 'text-white' : 'text-slate-200'}`}>
          <h2 className="type-preset-5 py-2 font-bold mr-2">{title}</h2>
          <span className="type-preset-5 py-2">{subtitle}</span>
      </header>

      {posts != null && posts.length > 0 &&
        <Slider {...settings}>
          {posts && posts.map((post, i) => (
              <NewsItemInsight post={post} key={i} theme={theme} />
          ))}
        </Slider>
      }
    </div>
  )
}
